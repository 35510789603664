import React, {Component} from 'react'
import {Popup, Button, Modal} from 'semantic-ui-react'
import {buttonStyle} from '../button-style'
import {stages, statuses} from '../../vpo-stages/options'
import $ from 'jquery'
import {saveVpoStageBulk} from '../../vpo-stages/save'
import {Auth0Context} from '../../../contexts/auth-context'

class BulkEditVpoStages extends Component
{
  static contextType = Auth0Context

  constructor(props)
  {
    super(props)
    this.state = {
      open: false,
      status: '',
      stage: [],
      color: ''
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.statusOnChange = this.statusOnChange.bind(this)
    this.colorOnChange = this.colorOnChange.bind(this)
    this.stageOnChange = this.stageOnChange.bind(this)
    this.saveStage = this.saveStage.bind(this)
  }

  openModal() {
    let {numSelected} = this.props

    if (numSelected == 0) {
      alert('Select at least 1 VPO')
      return
    } else {
      this.setState({
        open: true,
        status: '',
        stage: [],
        color: ''
      })
    }
  }

  closeModal() {
    this.setState({open: false})
  }

  saveStage() {
    let {stage, status, color} = this.state

    if (stage.length == 0) {
      alert('Stage is required!')
      $("#vpoStageSelect").trigger("focus")
      return true
    }

    if (status == '') {
      alert('Status is required!')
      $("#vpoStatusSelect").trigger("focus")
      return true
    }


    saveVpoStageBulk(status, stage, color, (err, res) => {
      if (err) {
        alert('There was an error when trying to save vpo stage.')
      } else {
        alert('VPO stage info saved!')
        this.closeModal()
        window.dispatchEvent(new Event('get-stage-text'))
      }
    })
  }

  statusOnChange({currentTarget}) {
    this.setState({status: currentTarget.value})
  }

  colorOnChange({currentTarget}) {
    this.setState({color: currentTarget.value})
  }

  stageOnChange({currentTarget}) {
    let value = $('#vpoStageSelect').val()
    this.setState({stage: value})
  }

  render() {
    const {customer} = this.context
    let {open, status, stage, color} = this.state
    let colors = customer ? customer.colors.map(x => x.name) : []
    return (
      <React.Fragment>
        <Modal
          open={open}
        >
          <Modal.Header>Stages</Modal.Header>
          <Modal.Content style={{fontSize: '20px'}}>
            <div style={{display: 'inline-block'}}>
              Stage &nbsp;&nbsp;&nbsp; <small style={{fontSize: '12px', opacity: 0.5}}>(Can select multiple)</small>
              <br/>
              <select multiple id="vpoStageSelect" onChange={this.stageOnChange} aria-label="Change Stage" style={{width: '300px', padding: '5px', height: '230px'}}>
                {stages.map((stage, index) =>
                  <option value={stage} key={index}>{stage}</option>
                )}
              </select>
            </div>
            <div style={{display: 'inline-block', marginLeft: '50px', verticalAlign: 'top'}}>
              Status
              <br/>
              <select id="vpoStatusSelect" value={status} onChange={this.statusOnChange} aria-label="Change Status" style={{width: '300px', padding: '5px'}}>
                <option value=''>Select</option>
                {statuses.map((status, index) =>
                  <option value={status} key={index}>{status}</option>
                )}
              </select>

              {
                stage.length == 1 && stage[0] == 'LD'
                ?
                <React.Fragment>
                  <br/><br/>
                  Color &nbsp;&nbsp;&nbsp; <small style={{fontSize: '12px', opacity: 0.5}}>(Optional)</small>
                  <br/>
                  <select id="vpoLDColorSelect" value={color} onChange={this.colorOnChange} aria-label="Change Color" style={{width: '300px', padding: '5px'}}>
                    <option value=''>Select</option>
                    {colors.map((color, index) =>
                      <option value={color} key={index}>{color}</option>
                    )}
                  </select>
                </React.Fragment>
                :
                null
              }

            </div>
            <small style={{fontSize: '12px', opacity: 0.5}}>
              <br/>For windows: Hold down the control (ctrl) button to select multiple options<br/>
              For Mac: Hold down the command button to select multiple options
            </small>
          </Modal.Content>
          <Modal.Actions>
            <Button color='black' onClick={this.closeModal}>
              Cancel
            </Button>
            <Button
              content="Save stage"
              labelPosition='right'
              icon='checkmark'
              onClick={this.saveStage}
              positive
            />
          </Modal.Actions>
        </Modal>
        <Popup content='Stages' position='bottom left'
          trigger={
            <Button basic icon='flag checkered' onClick={this.openModal} style={buttonStyle}/>
          }
        />
      </React.Fragment>
    )
  }
}

export default BulkEditVpoStages
