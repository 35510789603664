import Utils from '../../modules/utils'

const saveVpoStage = (status, stage, color, id, callbackFunc) => {
  const url = 'vpo_stages'
  const data = {status, stage, color, id}
  Utils.post(url, data, callbackFunc)
}

const saveVpoStageBulk = (status, stage, color, callbackFunc) => {
  let {vpoIds} = window
  let data = {vpoIds, status, stage, color}

  const url = 'vpo_stages_bulk'
  Utils.post(url, data, callbackFunc)
}

export {saveVpoStage, saveVpoStageBulk}
