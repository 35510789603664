import React, {Component} from 'react'
import {Tab, Grid, Button, Icon, Popup} from 'semantic-ui-react'
import {saveVpoStage} from './save.js'
import {getVpoStage} from './get.js'
import {stages, statuses} from './options.js'
import {getActiveIndex} from './active-index.js'
import {modal} from '../contacts/modal'
import $ from 'jquery'
import {saveNote} from './save-note.js'
import {formatDate} from '../cads/format-date.js'
import {deleteNote} from './delete-note.js'
import {successMessage} from './success-message.js'
import {Auth0Context} from '../../contexts/auth-context'

const initialState = () => {
  let result = {defaultActiveIndex: null, open: false, filenames: [], stage: null, notes: [], users: [], deletedNotes: []}
  stages.forEach(stage => result[stage] = {status: statuses[0]})
  return result
}

class VpoStages extends Component
{
  static contextType = Auth0Context

  constructor(props)
  {
    super(props)
    this.state = initialState()

    this.statusOnChange = this.statusOnChange.bind(this)
    this.statusSelect = this.statusSelect.bind(this)
    this.checkmark = this.checkmark.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.addNote = this.addNote.bind(this)
    this.saveNote = this.saveNote.bind(this)
    this.addFilename = this.addFilename.bind(this)
    this.removeFilename = this.removeFilename.bind(this)
    this.saveNoteSuccess = this.saveNoteSuccess.bind(this)
    this.deleteNote = this.deleteNote.bind(this)
    this.deleteNoteSuccess = this.deleteNoteSuccess.bind(this)
    this.ldRow = this.ldRow.bind(this)
    this.colorOnChange = this.colorOnChange.bind(this)
    this.colorSelect = this.colorSelect.bind(this)
    this.saveVpoStage = this.saveVpoStage.bind(this)
  }

  closeModal() {
    this.setState({open: false})
  }

  saveNote() {
    let {filenames, stage} = this.state
    let text = $('#addContactNote').val().trim()
    if (text != '') {
      let filename = filenames.join('@@@')
      saveNote(this.props.id, text, stage, filename, (err, res) => {
        if (err) {
          alert('There was an error when trying to save note.')
        } else {
          this.saveNoteSuccess(res)
          this.closeModal()
        }
      })
    }
  }

  saveNoteSuccess(res) {
    let {note, user} = res.data
    let {notes, users} = this.state
    notes.unshift(note)
    users.push(user)
    this.setState({notes, users})
  }

  addNote(stage) {
    this.setState({open: true, filenames: [], stage})
  }

  addFilename(filename) {
    let {filenames} = this.state
    filenames.push(filename)
    this.setState({filenames})
  }

  removeFilename(filename) {
    let {filenames} = this.state
    filenames = filenames.filter(x => x != filename)
    this.setState({filenames})
  }

  componentDidMount() {
    getVpoStage(this.props.id, (err, res) => {
      if (err) {
        alert('There was an error when trying to get vpo stage.')
      } else {
        let {data} = res
        if (data.stage != null) {
          let newState = JSON.parse(data.stage.replaceAll('=>', ': '))
          newState['defaultActiveIndex'] = getActiveIndex(newState)
          newState['notes'] = data.notes
          newState['users'] = data.users
          this.setState(newState)
        } else {
          let newState = {
            defaultActiveIndex: 0,
            notes: data.notes,
            users: data.users
          }
          this.setState(newState)
        }
      }
    })
  }

  statusOnChange(stage, color = '', {currentTarget}) {
    let newState = {}
    let status = currentTarget.value
    if (color != '') {
      newState[stage] = this.state[stage].filter(x => x['color'] != color)
      newState[stage].push({'status': status, 'color': color})
    } else {
      newState[stage] = {}
      newState[stage]['status'] = status
      if (this.state[stage]['color'] != null) {
        newState[stage]['color'] = this.state[stage]['color']
        color = this.state[stage]['color']
      }
    }

    this.setState(newState)
    this.saveVpoStage(status, stage, color)
  }

  saveVpoStage(status, stage, color) {
    saveVpoStage(status, stage, color, this.props.id, (err, res) => {
      if (err) {
        alert('There was an error when trying to save vpo stage.')
      } else {
        this.saveNoteSuccess(res)
        this.setState({showSuccess: true})
        setTimeout(() => {
          this.setState({showSuccess: false})
        }, 3000)
      }
    })
  }

  colorOnChange(stage, {currentTarget}) {
    let newState = {}
    let color = currentTarget.value
    let status = this.state[stage]['status']
    newState[stage] = {}
    newState[stage]['color'] = color
    newState[stage]['status'] = status
    this.setState(newState)
    this.saveVpoStage(status, stage, color)
  }

  checkmark(index, stage) {
    if (this.state[stage] != null && this.state[stage].length) {
      if (this.state[stage].every(x => x['status'].includes('Approved'))) {
        return '✓ '
      } else if (this.state[stage].some(x => x['status'].includes('Rejected'))) {
        return '✖ '
      } else {
        return ''+(index+1)+': '
      }
    } else if (this.state[stage] == null || this.state[stage]['status'] == null) {
      return ''
    } else if (this.state[stage]['status'].includes('Approved')) {
      return '✓ '
    } else if (this.state[stage]['status'].includes('Rejected')) {
      return '✖ '
    } else {
      return ''+(index+1)+': '
    }
  }

  deleteNote(note) {
    if (window.confirm('Are you sure you want to delete the note?')) {
      deleteNote(note.id, (err, res) => {
        if (err) {
          alert('There was an error when trying to delete note.')
        } else {
          this.deleteNoteSuccess(res)
        }
      })
    }
  }

  deleteNoteSuccess(res) {
    let {note} = res.data
    let {deletedNotes} = this.state
    deletedNotes.push(note)
    this.setState({deletedNotes})
  }

  panes() {
    let {showSuccess, notes, deletedNotes, users} = this.state

    return stages.map((stage, index) => {
      let stageNotes = notes.filter(x => x.stage == stage)

      return ({ menuItem: this.checkmark(index, stage)+stage, render: () =>
        <Tab.Pane style={{padding: '50px'}}>
          <Grid columns={2} divided style={{borderBottom: '1px solid rgb(238,238,238)', marginBottom: '20px'}}>
            <Grid.Row style={{paddingBottom: 0}}>
              <Grid.Column>
                <h1 style={{display: 'inline-block', borderBottom: '1px solid black', marginBottom: '50px'}}>{this.checkmark(index, stage)+stage}</h1>
              </Grid.Column>
              <Grid.Column style={{textAlign: 'center'}}>
                <div style={{textAlign: 'right', height: 0, position: 'relative', bottom: '20px'}}>{showSuccess ? successMessage : null}</div>
                {
                  this.state[stage].length
                  ?
                  <div style={{display: 'inline-block', textAlign: 'left'}}>
                    {
                      this.state[stage].sort((a, b) => {return a['color'] > b['color'] ? 1 : -1}).map(this.ldRow.bind(null, stage))
                    }
                  </div>
                  :
                  <div style={{display: 'inline-block', textAlign: 'left'}}>
                    Status
                    <br/>
                    {this.statusSelect(stage)}

                    {
                      stage == 'LD'
                      ?
                      <React.Fragment>
                        <br/><br/>
                        Color &nbsp;&nbsp;&nbsp; <small style={{fontSize: '12px', opacity: 0.5}}>(Optional)</small>
                        <br/>
                        {this.colorSelect(stage)}
                      </React.Fragment>
                      :
                      null
                    }
                  </div>
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Button style={{float: 'right'}} size='mini' color='green' onClick={() => this.addNote(stage)}>Add Note</Button>
          <span style={{fontSize: '16px', textAlign: 'bold'}}>Notes</span>

          {
            stageNotes.length == 0
            ?
            <div style={{opacity: 0.5}}>(No notes made yet)</div>
            :
            <ul>
              {
                stageNotes.map((note) => {
                  let {filename} = note
                  let filenames = []

                  if (filename != null) {
                    filenames = note.filename.split('@@@')
                  }

                  let isDeleted = deletedNotes.filter(deletedNote => deletedNote.id == note.id).length > 0
                  let hideDeleteButton = note.text.startsWith('Changed status') || isDeleted

                  return (
                    <li>
                      <pre style={isDeleted ? {textDecoration: 'line-through'} : {}}>{users.find(x => x.id == note.userId).name} on {formatDate(note.createdAt.split('T')[0])} — {note.text}<Popup content='Remove note' trigger={<Button onClick={this.deleteNote.bind(null, note)} color='red' basic size='mini' icon='trash' style={{marginLeft: '10px', padding: '5px', display: (hideDeleteButton ? 'none': 'inline-block')}}/>} /></pre>
                      {filenames.map(x => <a href={x} target="_blank">{x.slice(77)}</a>)}
                    </li>
                  )
                })
              }
            </ul>
          }
        </Tab.Pane>
      })
    })
  }

  ldRow(stage, row, index) {
    let maxLen = this.state[stage].length
    let lastElement = index == maxLen - 1

    return (
      <div style={{borderBottom: (lastElement ? '' : '1px solid rgb(235,235,235)'), paddingBottom: '10px', marginBottom: '10px'}}>
        Status
        <br/>
        {this.statusSelect(stage, row['color'])}
      </div>
    )
  }

  colorSelect(stage) {
    const {customer} = this.context
    let colors = customer ? customer.colors.map(x => x.name) : []

    let val

    if (this.state[stage] == null || this.state[stage]['color'] == null) {
      val = ''
    } else {
      val = this.state[stage]['color']
    }


    return (
      <React.Fragment>
        <select value={val} onChange={this.colorOnChange.bind(null, stage)} aria-label="Change Color">
          <option value=''>Select...</option>
          {colors.map((color, index) =>
            <option value={color} key={index}>{color}</option>
          )}
        </select>
        <br/><br/>
      </React.Fragment>
    )
  }

  statusSelect(stage, color) {
    let val, date

    if (color != null) {
      if (this.state[stage].find(row => row['color'] == color) == null || this.state[stage].find(row => row['color'] == color)['status'] == null) {
        val = 'Pending Approval'
        date = ''
      } else {
        val = this.state[stage].find(row => row['color'] == color)['status']
        date = this.state[stage].find(row => row['color'] == color)['date']
      }
    }  else {
      if (this.state[stage] == null || this.state[stage]['status'] == null) {
        val = 'Pending Approval'
        date = ''
      } else {
        val = this.state[stage]['status']
        date = this.state[stage]['date']
      }
    }

    return (
      <React.Fragment>
        <select value={val} onChange={this.statusOnChange.bind(null, stage, color)} aria-label="Change Status">
          {statuses.map((status, index) =>
            <option value={status} key={index}>{status}</option>
          )}
        </select>
        {
          color != null
          ?
          <React.Fragment>
            &nbsp;&nbsp;&nbsp;
            {color}
          </React.Fragment>
          :
          null
        }
        &nbsp;&nbsp;&nbsp;
        {date}
      </React.Fragment>
    )
  }

  render() {
    let {defaultActiveIndex, open, filenames} = this.state
    return (
      defaultActiveIndex == null
      ?
      null
      :
      <div style={{marginBottom: '50px'}}>
        {modal(open, this.closeModal, this.saveNote, filenames, this.addFilename, this.removeFilename)}
        <h4>Stages</h4>
        <Tab panes={this.panes()} defaultActiveIndex={defaultActiveIndex}/>
      </div>
    )
  }
}

export default VpoStages
