const org1companies = [
  {
    name: 'Bealls',
    logo_url: 'https://erp-rails-usa-production.s3.amazonaws.com/logos/bealls_logo.png'
  },
  {
    name: 'Burlington',
    logo_url: 'https://erp-rails-usa-production.s3.amazonaws.com/burlington_logo.png'
  },
  {
    name: 'Citi Trends',
    logo_url: 'https://ihlgroup-app-production.s3.amazonaws.com/citi_trends_logo.png'
  },
  {
    name: "dd's DISCOUNTS",
    logo_url: 'https://ihlgroup-app-production.s3.amazonaws.com/dds_logo.jpg'
  },
  {
    name: 'Ross Stores',
    logo_url: 'https://ihlgroup-app-production.s3.amazonaws.com/ross_logo.png'
  },
  {
    name: 'Tillys',
    logo_url: 'https://ihlgroup-app-production.s3.amazonaws.com/tillys_logo.png'
  },
  {
    name: 'TJ Maxx USA / Marshalls',
    logo_url: 'https://ihlgroup-app-production.s3.amazonaws.com/tjmaxx_logo.png'
  },
  {
    name: 'TJX Australia',
    logo_url: 'https://erp-rails-usa-production.s3.amazonaws.com/logos/tjx_australia_logo.jpg'
  },
  {
    name: 'Variety Wholesalers',
    logo_url: 'https://erp-rails-usa-production.s3.amazonaws.com/vw_logo.png'
  },
  {
    name: 'Winners',
    logo_url: 'https://erp-rails-usa-production.s3.amazonaws.com/winners_logo.svg'
  }
]

const docParserCustomerCompanies = {
  "1": org1companies,
  "2": org1companies
}

export {docParserCustomerCompanies}
