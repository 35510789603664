function getApiEndpoint() {
  if (window.location.href.indexOf('localhost') > -1) {
    return 'http://localhost:3000/api/';
  } else {
    return 'https://www.ihlerp.com/api/';
  }
}

function getS3BucketName() {
  if (window.location.href.indexOf('localhost') > -1) {
    return 'ihlgroup-app-development';
  } else {
    return 'ihlgroup-app-production';
  }
}

function getStripePublicKey() {
  return process.env.REACT_APP_STRIPE_PUBLIC_KEY
  //if (window.location.href.indexOf('localhost') > -1) {
  //   return 'pk_test_0NKOuCscWgwkO5osLO9zeyb1003JDtIoGK'
  // } else {
  //   return 'pk_live_aCGSmFs2r9sZJUxBFrBhHlKC00x659gs5D'
  // }
}

function getTempApiKey() {
  let profile = JSON.parse(sessionStorage.getItem('user'));

  let user_id = '';
  if (profile != null) {
    user_id = profile.user_id;

    //set token to user id if guest
    if (profile.email == 'Guest') {
      user_id = profile.token;
    }
  }

  if (window.location.href.indexOf('localhost') > -1) {
    return '915d47e4f85924a4126c00a0f7b4e2ba04c9d9f8b7ddc005119d907dfbcd174f792652e21df456d57c61dea4476538a13b54d9cf5bc25593fb6c37180a2cf79d@@@' + user_id;
  } else {
    return '5a120feba2102a6cf201c7b06b36befee66ff50297d22fd8f833644031f126da6cc34b56c40af085d0af30f617599626a62f5bdfbede0bb612f0e6187ec2f286@@@' + user_id;
  }
}

const seasonOptions = [
  { key: "BACK TO SCHOOL 18", text: 'BACK TO SCHOOL 18', value: 'BACK TO SCHOOL 18' },
  { key: "BACK TO SCHOOL 19", text: 'BACK TO SCHOOL 19', value: 'BACK TO SCHOOL 19' },
  { key: "BACK TO SCHOOL 20", text: 'BACK TO SCHOOL 20', value: 'BACK TO SCHOOL 20' },
  { key: "FALL", text: 'FALL', value: 'FALL' },
  { key: "HOLIDAY", text: 'HOLIDAY', value: 'HOLIDAY' },
  { key: "N/A", text: 'N/A', value: 'N/A' },
  { key: "SPRING", text: 'SPRING', value: 'SPRING' },
  { key: "SPRING/SUMMER", text: 'SPRING/SUMMER', value: 'SPRING/SUMMER' },
  { key: "SUMMER", text: 'SUMMER', value: 'SUMMER' },
  { key: "SS19", text: 'SS19', value: 'SS19' },
  { key: "SS20", text: 'SS20', value: 'SS20' },
  { key: "WINTER", text: 'WINTER', value: 'WINTER' },

  { key: "Q4 2020", text: 'Q4 2020', value: 'Q4 2020' },
  { key: "Q1 2021", text: 'Q1 2021', value: 'Q1 2021' },
  { key: "Q2 2021", text: 'Q2 2021', value: 'Q2 2021' },
  { key: "Q3 2021", text: 'Q3 2021', value: 'Q3 2021' },
  { key: "Q4 2021", text: 'Q4 2021', value: 'Q4 2021' },
  { key: "Q1 2022", text: 'Q1 2022', value: 'Q1 2022' },
  { key: "Q2 2022", text: 'Q2 2022', value: 'Q2 2022' },
]

const seasonOptionsAdmin = seasonOptions.slice(0);
seasonOptionsAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

const seasonOptionsDavid = seasonOptions.slice(0);
seasonOptionsDavid.unshift({ key: '90 DAYS', text: '90 DAYS', value: '90 DAYS' });
seasonOptionsDavid.unshift({ key: 'Cut to Order', text: 'Cut to Order', value: 'Cut to Order' });
seasonOptionsDavid.unshift({ key: 'New Arrivals', text: 'New Arrivals', value: 'New Arrivals' });

const seasonOptionsDavidAdmin = seasonOptionsDavid.slice(0);
seasonOptionsDavidAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

const qcOptions = [
  { key: 'Poor Hand Feel', text: 'Poor Hand Feel', value: 'Poor Hand Feel' },
  { key: 'Print Issues', text: 'Print Issues', value: 'Print Issues' },
  { key: 'Specs Issues', text: 'Specs Issues', value: 'Specs Issues' },
  { key: 'Hanger Quality Issues', text: 'Hanger Quality Issues', value: 'Hanger Quality Issues' },
  { key: 'Threads Showing', text: 'Threads Showing', value: 'Threads Showing' },
  { key: 'Color Issues', text: 'Color Issues', value: 'Color Issues' }
]

const qcOptionsAdmin = qcOptions.slice(0);
qcOptionsAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

const divisionOptions = [
  { key: 'Big Boy', text: 'Big Boy', value: 'Big Boy' },
  { key: 'Boys', text: 'Boys', value: 'Boys' },
  { key: 'Girls', text: 'Girls', value: 'Girls' },
  { key: 'Infant Boy', text: 'Infant Boy', value: 'Infant Boy' },
  { key: 'Infant Girl', text: 'Infant Girl', value: 'Infant Girl' },
  { key: 'Ladies', text: 'Ladies', value: 'Ladies' },
  { key: 'Ladies Plus Size', text: 'Ladies Plus Size', value: 'Ladies Plus Size' },
  { key: 'Men', text: 'Men', value: 'Men' },
  //{ key: 'Toddlers', text: 'Toddlers', value: 'Toddlers' },
  { key: 'Toddler Boy', text: 'Toddler Boy', value: 'Toddler Boy' },
  { key: 'Toddler Girl', text: 'Toddler Girl', value: 'Toddler Girl' },
  { key: 'Unisex', text: 'Unisex', value: 'Unisex' },
  //{ key: 'Backpacks', text: 'Backpacks', value: 'Backpacks' }
]

const divisionOptionsAdmin = divisionOptions.slice(0);
divisionOptionsAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

const divisionOptionsDavid = [
  { key: 'Boys', text: 'Boys', value: 'Boys' },
  { key: 'Girls', text: 'Girls', value: 'Girls' },
  { key: 'Men', text: 'Men', value: 'Men' },
  { key: 'Tech', text: 'Tech', value: 'Tech' },
  { key: 'Toddlers', text: 'Toddlers', value: 'Toddlers' },
  { key: 'Toys', text: 'Toys', value: 'Toys' },
  { key: "Womens", text: 'Womens', value: 'Womens' },
]

const divisionOptionsDavidAdmin = divisionOptionsDavid.slice(0);
divisionOptionsDavidAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });


const divisionOptionsFashionNova = [
  { key: 'Ladies', text: 'Ladies', value: 'Ladies' },
  { key: 'Ladies Plus Size', text: 'Ladies Plus Size', value: 'Ladies Plus Size' },
]

const brandOptions = [
  { key: 'AEROPOSTALE', text: 'AEROPOSTALE', value: 'AEROPOSTALE' },
  { key: 'BIG DREAMS', text: 'BIG DREAMS', value: 'BIG DREAMS' },
  { key: 'CATHERINE MALANDRINO', text: 'CATHERINE MALANDRINO', value: 'CATHERINE MALANDRINO' },
  { key: 'DAISY FUENTES', text: 'DAISY FUENTES', value: 'DAISY FUENTES' },
  { key: 'ELIE TAHARI', text: 'ELIE TAHARI', value: 'ELIE TAHARI' },
  { key: 'FASHION NOVA', text: 'FASHION NOVA', value: 'FASHION NOVA' },
  { key: 'FIT COLLECTIVE', text: 'FIT COLLECTIVE', value: 'FIT COLLECTIVE' },
  { key: 'FRENCH AFFAIR', text: 'FRENCH AFFAIR', value: 'FRENCH AFFAIR' },
  { key: 'GIRLS', text: 'GIRLS', value: 'GIRLS' },
  { key: 'ICY HOT', text: 'ICY HOT', value: 'ICY HOT' },
  { key: 'IH SPORT', text: 'IH SPORT', value: 'IH SPORT' },
  { key: 'IKE BEHAR', text: 'IKE BEHAR', value: 'IKE BEHAR' },
  { key: 'JOE MAX', text: 'JOE MAX', value: 'JOE MAX' },
  { key: 'JOE MAX JR', text: 'JOE MAX JR', value: 'JOE MAX JR' },
  { key: 'MED', text: 'MED', value: 'MED' },
  { key: 'PRIVATE LABEL', text: 'PRIVATE LABEL', value: 'PRIVATE LABEL' },
  { key: 'RAE DUNN', text: 'RAE DUNN', value: 'RAE DUNN' },
  { key: 'RAFAELLA', text: 'RAFAELLA', value: 'RAFAELLA' },
  { key: 'SECRET STYLES', text: 'SECRET STYLES', value: 'SECRET STYLES' },
  { key: 'SLIM & THIN', text: 'SLIM & THIN', value: 'SLIM & THIN' },
  { key: 'TAHARI', text: 'TAHARI', value: 'TAHARI' },
  { key: 'TOTALLY ADORBS', text: 'TOTALLY ADORBS', value: 'TOTALLY ADORBS' },
  { key: 'TOTALLY TWEEN', text: 'TOTALLY TWEEN', value: 'TOTALLY TWEEN' },
  { key: 'TWEEN QUEEN', text: 'TWEEN QUEEN', value: 'TWEEN QUEEN' },
  { key: 'WAIST WATCHER', text: 'WAIST WATCHER', value: 'WAIST WATCHER' },
];

const brandOptionsAdmin = brandOptions.slice(0);
brandOptionsAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

const brandOptionsFashionNova = [
  { key: 'FRENCH AFFAIR', text: 'FRENCH AFFAIR', value: 'FRENCH AFFAIR' },
  { key: 'ICY HOT', text: 'ICY HOT', value: 'ICY HOT' },
  { key: 'FASHION NOVA', text: 'FASHION NOVA', value: 'FASHION NOVA' },
  { key: 'PRIVATE LABEL', text: 'PRIVATE LABEL', value: 'PRIVATE LABEL' },
  { key: 'SECRET STYLES', text: 'SECRET STYLES', value: 'SECRET STYLES' },
  { key: 'SLIM & THIN', text: 'SLIM & THIN', value: 'SLIM & THIN' },
  { key: 'WAIST WATCHER', text: 'WAIST WATCHER', value: 'WAIST WATCHER' },
];

const brandOptionsDavid = [
  { key: 'BIG DREAMZ', text: 'BIG DREAMZ', value: 'BIG DREAMZ' },
  { key: 'CandyCat', text: 'CandyCat', value: 'CandyCat' },
  { key: 'LinDavid', text: 'LinDavid', value: 'LinDavid' },
  { key: 'Private Label', text: 'Private Label', value: 'Private Label' },
  { key: 'Swari', text: 'Swari', value: 'Swari' },
  { key: 'MEGA BRANDS', text: 'MEGA BRANDS', value: 'MEGA BRANDS' },
  { key: 'TOTALLY ADORBS', text: 'TOTALLY ADORBS', value: 'TOTALLY ADORBS' },
];

const brandOptionsDavidAdmin = brandOptionsDavid.slice(0);
brandOptionsDavidAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

const categoryOptions = [
  { key: 'Accessories', text: 'Accessories', value: 'Accessories' },
  { key: 'Activewear', text: 'Activewear', value: 'Activewear' },
  { key: 'Apparel', text: 'Apparel', value: 'Apparel' },
  { key: 'Basics / Intimates', text: 'Basics / Intimates', value: 'Basics / Intimates' },
  { key: 'Hosiery', text: 'Hosiery', value: 'Hosiery' },
  { key: 'Medical Supplies', text: 'Medical Supplies', value: 'Medical Supplies' },
  { key: 'Mobile Accessories', text: 'Mobile Accessories', value: 'Mobile Accessories' },
  { key: 'Shapewear', text: 'Shapewear', value: 'Shapewear' },
  { key: 'Sleep / Lounge', text: 'Sleep / Lounge', value: 'Sleep / Lounge' },
  { key: 'Socks', text: 'Socks', value: 'Socks' },
  { key: 'Toys', text: 'Toys', value: 'Toys' },
  //{ key: 'Backpacks', text: 'Backpacks', value: 'Backpacks' }
];

const categoryOptionsAdmin = categoryOptions.slice(0);
categoryOptionsAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

const categoryOptionsDdsAshley = [
  { key: 'Basics / Intimates', text: 'Basics / Intimates', value: 'Basics / Intimates' },
];

const categoryOptionsFashionNova = [
  { key: 'Accessories', text: 'Accessories', value: 'Accessories' },
  { key: 'Basics / Intimates', text: 'Basics / Intimates', value: 'Basics / Intimates' },
  { key: 'Shapewear', text: 'Shapewear', value: 'Shapewear' },
  { key: 'Sleep / Lounge', text: 'Sleep / Lounge', value: 'Sleep / Lounge' },
];

const colorOptions = [
  { key: 'SOLID', text: 'SOLID', value: 'SOLID' },
  { key: 'PRINT', text: 'PRINT', value: 'PRINT' },
  { key: 'VERBIAGE', text: 'VERBIAGE', value: 'VERBIAGE' },
  { key: 'BEIGE', text: 'BEIGE', value: 'BEIGE' },
  { key: 'BLACK', text: 'BLACK', value: 'BLACK' },
  { key: 'BLUE', text: 'BLUE', value: 'BLUE' },
  { key: 'BROWN', text: 'BROWN', value: 'BROWN' },
  { key: 'CORAL', text: 'CORAL', value: 'CORAL' },
  { key: 'GRAY', text: 'GRAY', value: 'GRAY' },
  { key: 'GREEN', text: 'GREEN', value: 'GREEN' },
  { key: 'HEATHERED BLACK', text: 'HEATHERED BLACK', value: 'HEATHERED BLACK' },
  { key: 'HEATHERED GRAY', text: 'HEATHERED GRAY', value: 'HEATHERED GRAY' },
  { key: 'IVORY', text: 'IVORY', value: 'IVORY' },
  { key: 'LAVENDER', text: 'LAVENDER', value: 'LAVENDER' },
  { key: 'MAROON', text: 'MAROON', value: 'MAROON' },
  { key: 'MAUVE', text: 'MAUVE', value: 'MAUVE' },
  { key: 'MUSTARD', text: 'MUSTARD', value: 'MUSTARD' },
  { key: 'NAVY', text: 'NAVY', value: 'NAVY' },
  { key: 'NUDE', text: 'NUDE', value: 'NUDE' },
  { key: 'OFF WHITE', text: 'OFF WHITE', value: 'OFF WHITE' },
  { key: 'ORANGE', text: 'ORANGE', value: 'ORANGE' },
  { key: 'PINK', text: 'PINK', value: 'PINK' },
  { key: 'PURPLE', text: 'PURPLE', value: 'PURPLE' },
  { key: 'RED', text: 'RED', value: 'RED' },
  { key: 'TEAL', text: 'TEAL', value: 'TEAL' },
  { key: 'WHITE', text: 'WHITE', value: 'WHITE' },
  { key: 'WINE / BURGUNDY', text: 'WINE / BURGUNDY', value: 'WINE / BURGUNDY' },
  { key: 'YELLOW', text: 'YELLOW', value: 'YELLOW' }
];

const colorOptionsAdmin = colorOptions.slice(0);
colorOptionsAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

let colorOptionsDavid = colorOptions.slice(0);
colorOptionsDavid.push({ key: 'RAINBOW', text: 'RAINBOW', value: 'RAINBOW' });
colorOptionsDavid.push({ key: 'ROSE', text: 'ROSE', value: 'ROSE' });
colorOptionsDavid.push({ key: 'MULTI COLOR', text: 'MULTI COLOR', value: 'MULTI COLOR' });

colorOptionsDavid.sort(function(a,b) {
  if (a.key > b.key) return 1;
  if (b.key > a.key) return -1;

  return 0;
});

const colorOptionsDavidAdmin = colorOptionsDavid.slice(0);
colorOptionsDavidAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });


const fabricationOptions = [
  { key: 'COTTON', text: 'COTTON', value: 'COTTON' },
  { key: 'COTTON/POLYESTER', text: 'COTTON/POLYESTER', value: 'COTTON/POLYESTER' },
  { key: 'HACCI', text: 'HACCI', value: 'HACCI' },
  { key: 'JERSEY', text: 'JERSEY', value: 'JERSEY' },
  { key: 'LACE', text: 'LACE', value: 'LACE' },
  { key: 'LACE COTTON/SPANDEX LINED', text: 'LACE COTTON/SPANDEX LINED', value: 'LACE COTTON/SPANDEX LINED' },
  { key: 'LACE/MESH LINED', text: 'LACE/MESH LINED', value: 'LACE/MESH LINED' }, //in vpo report as LACE MESH LINED, LACE & MESH LINED
  { key: 'LASER CUT', text: 'LASER CUT', value: 'LASER CUT' },
  { key: 'MICRO', text: 'MICRO', value: 'MICRO' },
  { key: 'MODAL', text: 'MODAL', value: 'MODAL' },
  { key: 'NYLON', text: 'NYLON', value: 'NYLON' },
  { key: 'NYLON/POLYESTER/SPANDEX', text: 'NYLON/POLYESTER/SPANDEX', value: 'NYLON/POLYESTER/SPANDEX' },
  { key: 'NYLON/POLYESTER', text: 'NYLON/POLYESTER', value: 'NYLON/POLYESTER' },
  { key: 'NYLON/SPANDEX', text: 'NYLON/SPANDEX', value: 'NYLON/SPANDEX' },
  { key: 'POLY', text: 'POLY', value: 'POLY' },
  { key: 'POLYAMIDE/SPANDEX', text: 'POLYAMIDE/SPANDEX', value: 'POLYAMIDE/SPANDEX' },
  { key: 'POLYESTER/SPANDEX', text: 'POLYESTER/SPANDEX', value: 'POLYESTER/SPANDEX' },
  { key: 'RAYON', text: 'RAYON', value: 'RAYON' },
  { key: 'RAYON/POLYESTER/SPANDEX', text: 'RAYON/POLYESTER/SPANDEX', value: 'RAYON/POLYESTER/SPANDEX' },
  { key: 'RIBBED SEAMLESS & LACE TRIM', text: 'RIBBED SEAMLESS & LACE TRIM', value: 'RIBBED SEAMLESS & LACE TRIM' },
  { key: 'SATIN', text: 'SATIN', value: 'SATIN' },
  { key: 'SEAMLESS', text: 'SEAMLESS', value: 'SEAMLESS' },
  { key: 'SILICONE', text: 'SILICONE', value: 'SILICONE' },
  { key: 'VELVET', text: 'VELVET', value: 'VELVET' },
  { key: 'WOVEN', text: 'WOVEN', value: 'WOVEN' }
];

const fabricationOptionsDavid = [
  { key: 'POLYESTER', text: 'POLYESTER', value: 'POLYESTER' },
  { key: 'PVC', text: 'PVC', value: 'PVC' },
  { key: 'PU LEATHER', text: 'PU LEATHER', value: 'PU LEATHER' },
  { key: 'PLUSH', text: 'PLUSH', value: 'PLUSH' },
  { key: 'FAUX LEATHER', text: 'FAUX LEATHER', value: 'FAUX LEATHER' },
];

const fabricationOptionsDavidAdmin = fabricationOptionsDavid.slice(0);
fabricationOptionsDavidAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

const fabricationOptionsAdmin = fabricationOptions.slice(0);
fabricationOptionsAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

const packsOptions = [
  { key: 'SINGLE', text: 'SINGLE', value: 'SINGLE' },
  { key: '2 PACK', text: '2 PACK', value: '2 PACK' },
  { key: '3 PACK', text: '3 PACK', value: '3 PACK' },
  { key: '4 PACK', text: '4 PACK', value: '4 PACK' },
  { key: '5 PACK', text: '5 PACK', value: '5 PACK' },
  { key: '5 PACK PACKAGED', text: '5 PACK PACKAGED', value: '5 PACK PACKAGED' },
  { key: '6 PACK', text: '6 PACK', value: '6 PACK' },
  { key: '7 PACK', text: '7 PACK', value: '7 PACK' },
  { key: '8 PACK', text: '8 PACK', value: '8 PACK' },
  { key: '9 PACK', text: '9 PACK', value: '9 PACK' },
  { key: '10 PACK', text: '10 PACK', value: '10 PACK' },
  { key: '11 PACK', text: '11 PACK', value: '11 PACK' },
  { key: '12 PACK', text: '12 PACK', value: '12 PACK' },
  { key: '13 PACK', text: '13 PACK', value: '13 PACK' },
  { key: '14 PACK', text: '14 PACK', value: '14 PACK' },
  { key: 'SET', text: 'SET', value: 'SET' },
  { key: 'PACKAGED', text: 'PACKAGED', value: 'PACKAGED' },
];

const packsOptionsAdmin = packsOptions.slice(0);
packsOptionsAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

const packsOptionsDavid = [
  { key: '12', text: '12', value: '12' },
  { key: '24', text: '24', value: '24' },
  { key: '48', text: '48', value: '48' },
];

const packsOptionsDavidAdmin = packsOptionsDavid.slice(0);
packsOptionsDavidAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

const marginsOptionsAdmin = [
  { key: 'A 55%+', text: 'A 55%+', value: 'A 55%+' },
  { key: 'B 45%-54%', text: 'B 45%-54%', value: 'B 45%-54%' },
  { key: 'C 36%-44%', text: 'C 36%-44%', value: 'C 36%-44%' },
  { key: 'D 25%-35%', text: 'D 25%-35%', value: 'D 25%-35%' },
  { key: 'E -24%', text: 'E -24%', value: 'E -24%' },
  { key: 'Missing', text: 'Missing', value: 'Missing' }
];

const subCategoryOptions = [
  { key: 'ACCESSORIES', text: 'ACCESSORIES', value: 'ACCESSORIES' },
    { key: 'AIR SPRAY', text: 'AIR SPRAY', value: 'AIR SPRAY' },
  { key: 'BABYDOLL', text: 'BABYDOLL', value: 'BABYDOLL' },
  { key: 'BODYSUIT', text: 'BODYSUIT', value: 'BODYSUIT' },
  { key: 'BRA', text: 'BRA', value: 'BRA' },
  { key: 'BRA & PANTY SET', text: 'BRA & PANTY SET', value: 'BRA & PANTY SET' },
  { key: 'BRALETTE/SOFT BRA', text: 'BRALETTE/SOFT BRA', value: 'BRALETTE/SOFT BRA' },
  { key: 'CAMI', text: 'CAMI', value: 'CAMI' },
  { key: 'CHEMISE/SLEEPSHIRT', text: 'CHEMISE/SLEEPSHIRT', value: 'CHEMISE/SLEEPSHIRT' },
    { key: 'CLEANING SUPPLIES', text: 'CLEANING SUPPLIES', value: 'CLEANING SUPPLIES' },
  { key: 'CORSET', text: 'CORSET', value: 'CORSET' },
  { key: 'FOOTIE', text: 'FOOTIE', value: 'FOOTIE' },
  { key: 'GOWN-MAXIDRESS', text: 'GOWN-MAXIDRESS', value: 'GOWN-MAXIDRESS' },
    { key: 'HAND SANITIZER', text: 'HAND SANITIZER', value: 'HAND SANITIZER' },
  { key: 'LEGGINGS', text: 'LEGGINGS', value: 'LEGGINGS' },
  //{ key: 'LONG PANT/CAPRI', text: 'LONG PANT/CAPRI', value: 'LONG PANT/CAPRI' },
    { key: 'FACE MASKS', text: 'FACE MASKS', value: 'FACE MASKS' },
  { key: 'MISC', text: 'MISC', value: 'MISC' },
  { key: 'ONESIE', text: 'ONESIE', value: 'ONESIE' },
  { key: 'PACKAGED', text: 'PACKAGED', value: 'PACKAGED' },
  { key: 'PANT', text: 'PANT', value: 'PANT' },
  { key: 'PANTY', text: 'PANTY', value: 'PANTY' },
  { key: 'PJ SET', text: 'PJ SET', value: 'PJ SET' },
  { key: 'ROBE-WRAP', text: 'ROBE-WRAP', value: 'ROBE-WRAP' },
  { key: 'SHORTS', text: 'SHORTS', value: 'SHORTS' },
  { key: 'SLIPS/SKIRTS', text: 'SLIPS/SKIRTS', value: 'SLIPS/SKIRTS' },
  { key: 'SOCKS', text: 'SOCKS', value: 'SOCKS' },
  { key: 'SWEATSHIRT/HOODIE', text: 'SWEATSHIRT/HOODIE', value: 'SWEATSHIRT/HOODIE' },
  { key: 'TANK TOP', text: 'TANK TOP', value: 'TANK TOP' },
  { key: 'TEDDY', text: 'TEDDY', value: 'TEDDY' },
  { key: 'TOP', text: 'TOP', value: 'TOP' },
  { key: 'TRAVEL SET', text: 'TRAVEL SET', value: 'TRAVEL SET' },
  { key: 'UNDERSHIRTS', text: 'UNDERSHIRTS', value: 'UNDERSHIRTS' },
  { key: 'UNDERWEAR', text: 'UNDERWEAR', value: 'UNDERWEAR' },
  { key: 'WAIST CLINCHER', text: 'WAIST CLINCHER', value: 'WAIST CLINCHER' }
];

function getApplicableSubCategoryOptions(parentCategory, role) {
  var result;
  if (role == 'Admin') {
    result = subCategoryOptionsAdmin.slice(0);
  } else if (role == 'FashionNova') {
    result = subCategoryOptionsFashionNova.slice(0);
  } else if (role == 'DdsAshley') {
    result = subCategoryOptionsDdsAshley.slice(0);
  } else if (role == 'David') {
    result = subCategoryOptionsDavidAdmin.slice(0);
  } else if (role == 'DavidNOTADMIN') {
    result = subCategoryOptionsDavid.slice(0);
  } else {
    result = subCategoryOptions.slice(0);
  }

  var removeFromSleepLounge = [
    "ACCESSORIES",
    "BABYDOLL",
    "BODYSUIT",
    "BRA",
    "BRA & PANTY SET",
    "BRALETTE/SOFT BRA",
    "CAMI",
    "CORSET",
    "LEGGINGS",
    "PANTY",
    "SLIPS/SKIRTS",
    "SOCKS",
    "SWEATSHIRT/HOODIE",
    "TEDDY",
    "UNDERSHIRTS",
    "UNDERWEAR",
    "WAIST CLINCHER",
    'AIR SPRAY',
    'CLEANING SUPPLIES',
    'HAND SANITIZER',
    'FACE MASKS'
  ];

  if (parentCategory.length == 1) {
    if (parentCategory[0] == "Sleep / Lounge") {
      result = result.filter(subCategory => removeFromSleepLounge.indexOf(subCategory.key) == -1);
    }
  }

  var removeFromBasicsIntimates = [
    "ACCESSORIES",
    "CHEMISE/SLEEPSHIRT",
    "GOWN-MAXIDRESS",
    "LEGGINGS",
    //"LONG PANT / CAPRI SET",
    "ONESIE",
    "PANT",
    "PANT SET",
    "PJ SET",
    "ROBE-WRAP",
    "SHORT SET",
    "SHORTS",
    "SWEATSHIRT/HOODIE",
    "TANK TOP",
    "TOP",
    "TRAVEL SET",
    "FOOTIE",
    'AIR SPRAY',
    'CLEANING SUPPLIES',
    'HAND SANITIZER',
    'FACE MASKS'
  ];

  if (parentCategory.length == 1) {
    if (parentCategory[0] == "Basics / Intimates") {
      result = result.filter(subCategory => removeFromBasicsIntimates.indexOf(subCategory.key) == -1);
    }
  }

  var removeFromApparel = [
    'ACCESSORIES',
    'BABYDOLL',
    'BODYSUIT',
    'BRA',
    'BRA & PANTY SET',
    'BRALETTE/SOFT BRA',
    'CAMI',
    'CHEMISE/SLEEPSHIRT',
    'CORSET',
    'FOOTIE',
    'GOWN-MAXIDRESS',
    'LEGGINGS',
    'MISC',
    'ONESIE',
    'PACKAGED',
    //'PANT',
    'PANTY',
    'PJ SET',
    'ROBE-WRAP',
    //'SHORTS',
    'SLIPS/SKIRTS',
    'SOCKS',
    'SWEATSHIRT/HOODIE',
    'TANK TOP',
    'TEDDY',
    //'TOP',
    'TRAVEL SET',
    'UNDERSHIRTS',
    'UNDERWEAR',
    'WAIST CLINCHER',
    'AIR SPRAY',
    'CLEANING SUPPLIES',
    'HAND SANITIZER',
    'FACE MASKS'
  ];

  if (parentCategory.length == 1) {
    if (parentCategory[0] == "Apparel") {
      result = result.filter(subCategory => removeFromApparel.indexOf(subCategory.key) == -1);
    }
  }

  let onlyMedicalSupplies = [
    'AIR SPRAY',
    'CLEANING SUPPLIES',
    'HAND SANITIZER',
    'FACE MASKS'
  ]

  if (parentCategory.length == 1) {
    if (parentCategory[0] == "Medical Supplies") {
      result = result.filter(subCategory => onlyMedicalSupplies.indexOf(subCategory.key) != -1)
    }
  }

  if (parentCategory.length > 0 && parentCategory.indexOf("Medical Supplies") == -1) {
    result = result.filter(subCategory => onlyMedicalSupplies.indexOf(subCategory.key) == -1)
  }


  return result;
}

const subCategoryOptionsAdmin = subCategoryOptions.slice(0);
subCategoryOptionsAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

const subCategoryOptionsDdsAshley = [
  { key: 'PANTY', text: 'PANTY', value: 'PANTY' },
]

const subCategoryOptionsDavid = [
  { key: 'Backpacks', text: 'Backpacks', value: 'Backpacks' },
  { key: 'Cosmetic Bag', text: 'Cosmetic Bag', value: 'Cosmetic Bag' },
  { key: 'Cross Body', text: 'Cross Body', value: 'Cross Body' },

  { key: 'Fannypack Womens', text: 'Fannypack Womens', value: 'Fannypack Womens' },
  { key: 'Fannypack Girls', text: 'Fannypack Girls', value: 'Fannypack Girls' },

  { key: 'Plush Bags', text: 'Plush Bags', value: 'Plush Bags' },

  { key: 'PU Bags', text: 'PU Bags', value: 'PU Bags' },
  { key: 'School Bags 16"', text: 'School Bags 16"', value: 'School Bags 16"' },
  { key: 'SOCKS', text: 'SOCKS', value: 'SOCKS' },
  { key: 'Wallets', text: 'Wallets', value: 'Wallets' },
]

const subCategoryOptionsDavidAdmin = subCategoryOptionsDavid.slice(0);
subCategoryOptionsDavidAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

const subCategoryOptionsFashionNova = [
  { key: 'ACCESSORIES', text: 'ACCESSORIES', value: 'ACCESSORIES' },
  { key: 'BABYDOLL', text: 'BABYDOLL', value: 'BABYDOLL' },
  { key: 'BODYSUIT', text: 'BODYSUIT', value: 'BODYSUIT' },
  { key: 'BRA', text: 'BRA', value: 'BRA' },
  { key: 'BRA & PANTY SET', text: 'BRA & PANTY SET', value: 'BRA & PANTY SET' },
  { key: 'BRALETTE/SOFT BRA', text: 'BRALETTE/SOFT BRA', value: 'BRALETTE/SOFT BRA' },
  { key: 'CAMI', text: 'CAMI', value: 'CAMI' },
  { key: 'CHEMISE/SLEEPSHIRT', text: 'CHEMISE/SLEEPSHIRT', value: 'CHEMISE/SLEEPSHIRT' },
  { key: 'CORSET', text: 'CORSET', value: 'CORSET' },
  { key: 'GOWN-MAXIDRESS', text: 'GOWN-MAXIDRESS', value: 'GOWN-MAXIDRESS' },
  { key: 'LEGGINGS', text: 'LEGGINGS', value: 'LEGGINGS' },
  { key: 'MISC', text: 'MISC', value: 'MISC' },
  { key: 'PACKAGED', text: 'PACKAGED', value: 'PACKAGED' },
  { key: 'PANT', text: 'PANT', value: 'PANT' },
  { key: 'PANTY', text: 'PANTY', value: 'PANTY' },
  { key: 'PJ SET', text: 'PJ SET', value: 'PJ SET' },
  { key: 'ROBE-WRAP', text: 'ROBE-WRAP', value: 'ROBE-WRAP' },
  { key: 'SHORTS', text: 'SHORTS', value: 'SHORTS' },
  { key: 'SLIPS/SKIRTS', text: 'SLIPS/SKIRTS', value: 'SLIPS/SKIRTS' },
  { key: 'TANK TOP', text: 'TANK TOP', value: 'TANK TOP' },
  { key: 'TEDDY', text: 'TEDDY', value: 'TEDDY' },
  { key: 'TOP', text: 'TOP', value: 'TOP' },
  { key: 'TRAVEL SET', text: 'TRAVEL SET', value: 'TRAVEL SET' },
  //{ key: 'UNDERGARMENT', text: 'UNDERGARMENT', value: 'UNDERGARMENT' },
  //{ key: 'UNDERSHIRTS', text: 'UNDERSHIRTS', value: 'UNDERSHIRTS' },
  //{ key: 'UNDERWEAR', text: 'UNDERWEAR', value: 'UNDERWEAR' },
];

const babydollSub2_options = [ //_and_bodysuits
  { key: 'MOLDED CUP', text: 'MOLDED CUP', value: 'MOLDED CUP' },
  { key: 'SOFT CUP', text: 'SOFT CUP', value: 'SOFT CUP' }
];

const babydollSub2_optionsAdmin = babydollSub2_options.slice(0);
babydollSub2_optionsAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

const braSub2_options = [
  { key: 'BANDEAU', text: 'BANDEAU', value: 'BANDEAU' },
  { key: 'COMFORT', text: 'COMFORT', value: 'COMFORT' },
  { key: 'LONG LINE', text: 'LONG LINE', value: 'LONG LINE' },
  { key: 'MOLDED CUPS', text: 'MOLDED CUPS', value: 'MOLDED CUPS' },
  //{ key: 'NOVELTY PUSH-UP', text: 'NOVELTY PUSH-UP', value: 'NOVELTY PUSH-UP' }, //removed 1/29/20
  { key: 'PUSH-UP', text: 'PUSH-UP', value: 'PUSH-UP' },
  { key: 'REMOVABLE CUPS', text: 'REMOVABLE CUPS', value: 'REMOVABLE CUPS' },
  { key: 'SOFT CUP', text: 'SOFT CUP', value: 'SOFT CUP' },
  { key: 'SPORTS BRA', text: 'SPORTS BRA', value: 'SPORTS BRA' },
  { key: 'STRAPLESS', text: 'STRAPLESS', value: 'STRAPLESS' },
  { key: 'WIRE-FREE', text: 'WIRE-FREE', value: 'WIRE-FREE' } //WIRELESS to WIRE-FREE 1/29/20
];

const braSub2_optionsAdmin = braSub2_options.slice(0);
braSub2_optionsAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

const pantySub2_options = [
  { key: 'BIKINI', text: 'BIKINI', value: 'BIKINI' },
  { key: 'BOYSHORT / BOYLEG', text: 'BOYSHORT / BOYLEG', value: 'BOYSHORT / BOYLEG' },
  { key: 'BRIEF', text: 'BRIEF', value: 'BRIEF' },
  { key: 'CHEEKY / TANGA', text: 'CHEEKY / TANGA', value: 'CHEEKY / TANGA' },
  { key: 'GSTRING', text: 'GSTRING', value: 'GSTRING' },
  { key: 'HIGHWAISTED', text: 'HIGHWAISTED', value: 'HIGHWAISTED' },
  { key: 'HIPSTER', text: 'HIPSTER', value: 'HIPSTER' },
  { key: 'OTHER', text: 'OTHER', value: 'OTHER' },
  { key: 'THONG', text: 'THONG', value: 'THONG' },
];

const pantySub2_optionsAdmin = pantySub2_options.slice(0);
pantySub2_optionsAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

const brapantysetSub2_options = [
  { key:"BRA w/ BIKINI", text: 'BRA w/ BIKINI', value: 'BRA w/ BIKINI' },
  { key:"BRA w/ BOYSHORT / BOYLEG", text: 'BRA w/ BOYSHORT / BOYLEG', value: 'BRA w/ BOYSHORT / BOYLEG' },
  { key:"BRA w/ BRIEF", text: 'BRA w/ BRIEF', value: 'BRA w/ BRIEF' },
  { key:"BRA w/ CHEEKY / TANGA", text: 'BRA w/ CHEEKY / TANGA', value: 'BRA w/ CHEEKY / TANGA' },
  { key:"BRA w/ GSTRING", text: 'BRA w/ GSTRING', value: 'BRA w/ GSTRING' },
  { key:"BRA w/ HIPSTER", text: 'BRA w/ HIPSTER', value: 'BRA w/ HIPSTER' },
  { key:"BRA w/ HIGHWAISTED", text: 'BRA w/ HIGHWAISTED', value: 'BRA w/ HIGHWAISTED' },
  { key:"BRA w/ THONG", text: 'BRA w/ THONG', value: 'BRA w/ THONG' }
];

const brapantysetSub2_optionsAdmin = brapantysetSub2_options.slice(0);
brapantysetSub2_optionsAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });




//LEGGINGSCATEGORY
// const leggingsSub2_options = [
//   { key: "CROPPED", text: 'CROPPED', value: 'CROPPED' },
// ];

//PANTCATEGORY =
const pantSub2_options = [
  { key: "CROPPED", text: 'CROPPED', value: 'CROPPED' },
];

const pantSub2_optionsAdmin = pantSub2_options.slice(0);
pantSub2_optionsAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

//PANTSETCATEGORY =
// const pantsetSub2_options = [
//   { key: "CROPPED", text: 'CROPPED', value: 'CROPPED' },
// ]

const shortsetSub2_options = [
  { key: "SHORT & CAMI", text: 'SHORT & CAMI', value: 'SHORT & CAMI' },
];

const shortsetSub2_optionsAdmin = shortsetSub2_options.slice(0);
shortsetSub2_optionsAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

//
// SHORTSETCATEGORY = [
//   "SHORT & CAMI"
// ]
//



const undershirtsSub2_options = [
  { key: "A-SHIRT", text: 'A-SHIRT', value: 'A-SHIRT' },
  { key: "CREW NECK", text: 'CREW NECK', value: 'CREW NECK' },
  { key: "V-NECK", text: 'V-NECK', value: 'V-NECK' },
];

const undershirtsSub2_optionsAdmin = undershirtsSub2_options.slice(0);
undershirtsSub2_optionsAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

// UNDERSHIRTSCATEGORY = [
//   "A-SHIRT",
//   "CREW NECK",
//   "V-NECK"
// ]
//

const underwearSub2_options = [
  { key: "BOXER BRIEF", text: 'BOXER BRIEF', value: 'BOXER BRIEF' },
  { key: "BRIEF", text: 'BRIEF', value: 'BRIEF' },
  { key: "LOW-RISE BRIEF", text: 'LOW-RISE BRIEF', value: 'LOW-RISE BRIEF' },
  { key: "PLAYSHORT", text: 'PLAYSHORT', value: 'PLAYSHORT' },
];

const underwearSub2_optionsAdmin = underwearSub2_options.slice(0);
underwearSub2_optionsAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

// UNDERWEARCATEGORY = [
//   "BOXER BRIEF",
//   "BRIEF",
//   "LOW-RISE BRIEF",
//   "PLAYSHORT"
// ]
//

const pjsetSub2_options = [
  { key: "CAPRI SET", text: 'CAPRI SET', value: 'CAPRI SET' },
  { key: "LONG PANT SET", text: 'LONG PANT SET', value: 'LONG PANT SET' },
  { key: "LONG SLEEVE SET", text: 'LONG SLEEVE SET', value: 'LONG SLEEVE SET' },
  { key: "SHORT SET", text: 'SHORT SET', value: 'SHORT SET' },
  { key: "TANK SET", text: 'TANK SET', value: 'TANK SET' },
];

const pjsetSub2_optionsAdmin = pjsetSub2_options.slice(0);
pjsetSub2_optionsAdmin.push({ key: 'Missing', text: 'Missing', value: 'Missing' });

// PJSETCATEGORY = [
//   "CAPRI SET",
//   "LONG PANT SET",
//   "LONG SLEEVE SET",
//   "SHORT SET",
//   "TANK SET"
// ]


const bulkStatusOptions = [
  // { key: 'Active', text: 'Active', value: 'Active' },
  // { key: 'Cut to order', text: 'Cut to order', value: 'Cut to order' },
  { key: 'Mark as Deals', text: 'Mark as Deals', value: 'Mark as Deals' },
  { key: 'Mark as NOT Deals', text: 'Mark as NOT Deals', value: 'Mark as NOT Deals' },
  // { key: 'Inactive', text: 'Inactive', value: 'Inactive' },
  // { key: 'Not approved', text: 'Not approved', value: 'Not approved' }
]

const kateAccounts = [
"10 SPOT",
"99 CENTS ONLY STORES",
"A & E STORES INC:B. BOMAN & CO., INC.",
"ALKO DISTRIBUTORS INC.",
"BACKCOUNTRY.COM",
"BEALL'S",
"BEALL'S:BEALL'S OUTLET",
"Burlington:Burlington Kids",
"Burlington:Burlington Mens",
"CATCO",
"CATO",
"Century 21 Stores",
"DANICE",
"DD'S:DDS - AMY",
"DD'S:DDS - ASHLEY",
"DD'S:DDS - KIDS",
"EASY  PICKINS",
"FORMAN  MILLS INC",
"GABRIEL  BROS.",
"ROSS:ROSS KIDS",
"ROSS:ROSS MEN",
"Stage Stores Inc.",
"TELCO",
"TILLYS ECOMM",
"TJX-EU",
"TJX/Marshalls",
"UNITED FASHION OF TEXAS,LTD",
"WINNERS MERCHANTS INC.",
]


const taylorAccounts = [
  "99 CENTS ONLY STORES",
  "Altar’d State",
  "BEALL'S",
  "BEALL'S:BEALL'S OUTLET",
  "BEALL'S:bealls Dept Stores",
  "BIG BOX OUTLET",
  "Burlington:Burlington Sleep",
  "Century 21 Stores",
  "DAVID'S BRIDAL",
  "DD'S:DDS - Allie",
  "DOLLS KILL",
  "FASHION NOVA",
  "Five Below Merchandising, Inc.",
  "GABRIEL  BROS.",
  "Gordmans",
  "JC PENNEY",
  "LOT LESS",
  "LTD Commodities, LLC",
  "LULU'S INC",
  "MODCLOTH INC.",
  "Macy's / bloomingdale's:Bloomingdale's Outlet",
  "Macy's / bloomingdale's:MACY'S DEPT",
  "Macy's / bloomingdale's:MACY'S DROPSHIP",
  "Macy's / bloomingdale's:Macy's Backstage",
  "NEIMAN MARCUSS",
  "NEX - NAVY EXCHANGE SERVICE",
  "NORDSTROM RACK",
  "PRETTY LITTLE THING",
  "ROSS:ROSS SLEEP",
  "ROSS:ROSS TAHARI",
  "RUE21",
  "Rue La La, Inc.",
  "SILVER ICING",
  "Stage Stores Inc.",
  "Stein Mart Inc.",
  "TJX AUSTRALIA",
  "TJX ECOMM",
  "TJX-EU",
  "VARIETY WHOLESALERS INC.",
  "WINDSOR",
  "WINNERS MERCHANTS INC.",
  "venus.com",
]

const nicoleAccounts = [
  "99 CENTS ONLY STORES",
  "Altar’d State",
  "BEALL'S",
  "BEALL'S:BEALL'S OUTLET",
  "BEALL'S:bealls Dept Stores",
  "BIG BOX OUTLET",
  "Burlington:Burlington Sleep",
  "Century 21 Stores",
  "DAVID'S BRIDAL",
  "DD'S:DDS - Allie",
  "DOLLS KILL",
  "FASHION NOVA",
  "Five Below Merchandising, Inc.",
  "GABRIEL  BROS.",
  "Gordmans",
  "LOT LESS",
  "LTD Commodities, LLC",
  "LULU'S INC",
  "MODCLOTH INC.",
  "Macy's / bloomingdale's:Bloomingdale's Outlet",
  "Macy's / bloomingdale's:MACY'S DEPT",
  "Macy's / bloomingdale's:MACY'S DROPSHIP",
  "Macy's / bloomingdale's:Macy's Backstage",
  "NEIMAN MARCUSS",
  "NEX - NAVY EXCHANGE SERVICE",
  "NORDSTROM RACK",
  "PRETTY LITTLE THING",
  "ROSS:ROSS SLEEP",
  "RUE21",
  "Rue La La, Inc.",
  "SILVER ICING",
  "Stage Stores Inc.",
  "Stein Mart Inc.",
  "TJX AUSTRALIA",
  "TJX ECOMM",
  "TJX-EU",
  "VARIETY WHOLESALERS INC.",
  "WINDSOR",
  "venus.com",
]

const alexAccounts = [
  "ARMY AND NAVY",
  "ATREVIDA",
  "BELA INTIMA",
  "BELA INTIMA/ INVERSIONES MAG",
  "CATO",
  "CITI TRENDS:CITI TRENDS - KDS",
  "CITI TRENDS:CITI TRENDS - PAT",
  "COPPEL",
  "DISCOVERY",
  "EMPRESA CURBELO DURAN INC",
  "Hilda Sanchez",
  "Hilda Sanchez:Rosalba Luna",
  "JACQUELINE INTIMATES",
  "KAT KAT",
  "LA CUSTOMERS:ANEMONE CORP",
  "LA CUSTOMERS:BEST LINE INC",
  "LA CUSTOMERS:CONSTANT TRADING",
  "LA CUSTOMERS:SMILE LINGERIE",
  "MAVICCI CORP",
  "ROSS:ROSS TAHARI",
  "Stein Mart Inc.",
  "TARGET S.A PANAMA",
  "TELCO",
  "TILLYS",
  "TJX/Marshalls",
  "VIVA FASHION",
  "WILCOTEX S.A.",
  "WINNERS MERCHANTS INC.",
]

const amandaAccounts = [
  "Burlington:Burlington Kids",
  "ROSS:ROSS KIDS",
  "DD'S:DDS - KIDS",
  "Burlington:Burlington activewear",
  "Dollar General - Dolgencorp, LLC",
  "ROSS:ROSS activewear",

  "BEALL'S:BEALL'S OUTLET", //should only show kids and ladies active
  "BEALL'S:bealls Dept Stores",
  "TJX AUSTRALIA",//should only show kids and ladies active
  "TJX ECOMM",
  "TJX-EU",
  "TJX/Marshalls",
]

const testAccounts = ["FASHION NOVA"]

const canSeeAccounts = {
  "kate@ihlgroup.com": kateAccounts,
  "taylor@ihlgroup.com": taylorAccounts,
  "nicole@ihlgroup.com": nicoleAccounts,
  "alex@ihlgroup.com": alexAccounts,
  "sales@ihlgroup.com": amandaAccounts,
  "amanda.g@ihlgroup.com": amandaAccounts,
  "danielle.luca@ihlgroup.com": amandaAccounts
}

//sales assistants can see sales admins orders
const salesAdmins = {
  //assistant    //admins
  "taylor@ihlgroup.com": ["nicole@ihlgroup.com"],
  "danielle.luca@ihlgroup.com": ["amanda.g@ihlgroup.com"],
  "alex@ihlgroup.com": ["izak@ihlgroup.com", "sami@ihlgroup.com", "kate@ihlgroup.com"],
  "diana@ihlgroup.com": ["izak@ihlgroup.com", "sami@ihlgroup.com", "kate@ihlgroup.com"]
}


const buyerOptions = [
  { key: 'MORDY', text: 'MORDY', value: 'MORDY' },
  { key: 'PATTY', text: 'PATTY', value: 'PATTY' },
  { key: 'SHIRLEY', text: 'SHIRLEY', value: 'SHIRLEY' },
  { key: 'TBD', text: 'TBD', value: 'TBD' },
]


let sizes = [
  'S-M-L-XL (1-2-2-1)',
  'S-M-L-XL (1-1-1-1)',
  'S-M-L-XL (2-4-4-2)',
  "1X-2X (2-2)",
  '1X-2X-3X (2-2-2)',
  '1X-2X-3X (2-1-1)',
  '1X-2X-3X (3-2-1)',
  'S-M-L (2-2-2)', //"S-M-L" //'S,M,L - 2,2,2',
  'M-L-XL (2-2-2)',
  'M-L-XL-2X (1-2-2-1)', //"M(1) L(2) XL(2) 2XL(1)" // "M(1)-L(2)-XL(2)-2X(1)" //  "M(1)-L(2)-1XL(2)-2XL(1)"
  'M-L-1X-2X (1-2-2-1)',
  "M-L-XL-2XL (1-2-2-1)",
  'L-XL-2X (2-2-2)',

  //filter for these in vpo
  '2T-3T-4T (1-1-1)', //(1)2T-(1)3T-(1)4T
  '2T-3T-4T (2-2-2)', //(2)2T - (2)3T - (2)4T    //'2T,3T,4T - 2 OF EACH',any of these?
  '2T/3T-4T (2-4)', //2T/3T(2) - 4T(4)
  '2T/3T-4T (2-2)', //2T/3T(2)-4T(2)


  'JUNIOR ONE SIZE', //'JUNIOR ONE SIZE FITS MOST'
  'ONE SIZE', //'ONE SIZE FITS ALL',



  "S/M-M/L (3-3)",
  "S-M-L-XL (ratio unknown)",
  "1X-2X-3X (ratio unknown)",
  "S-M-L-XL-1X-2X-3X (ratio unknown)",






  '12M-18M-24M (1-1-1)',


  //SOCKS
  '9-11',

  '36D-38C-38D-40C-40D-42D (1-1-1-1-1-1)',
  '36DD-38D-38DD-40D-40DD-42D (1-1-1-1-1-1)',
  '34B-34C-36B-36C-38C (1-1-1-2-1)',
  '30A-32A-34A (2-2-2)',
  '38C-38D-40C-40D-42C-42D (1-1-1-1-1-1)',
  '34B/S-34C/M-36B/M-36C/L-38C/XL (1-1-1-2-1)',
  '36DD/1X-38D/1X-38DD/2X-40D/2X-40DD/3X-42D/3X (1-1-1-1-1-1)',
  'XS','S','M','L','XL','2XL','1X','2X','3X',


  // '34B,34C,36B,36C,38C - 1,1,1,2,1',
  // '36DD,38D,38DD,40D,40DD,42D - ONE OF EACH',
  // '34B/S,34C/M,36B/M36C/L38C/XL - 1,1,1,2,1',
  // '36DD/1X,38D/1X,38DD/2X,40D/2X,40DD/3X,42D/3X - ONE OF EACH',
  // '30A,32A,34A - 2 OF EACH',
  // '4/5,6/6X - 3,3',
  //
  // '4/6,8/10,12/14,16/18 - 1,2,2,1'


  '8-10-12 (2-2-2)'

]

let sizesCheckout = [
  'PREPACK',
  "XXS",
  'XS',
  'S',
  "S/M",
  'M',
  'L',
  "L/XL",
  'XL',
  '1XL',
  '2XL',
  '1X',
  '2X',
  '3X',
  "4X",
  "5X",
  'XXL',
  'XXXL',
  '30A',
  '32A',
  '34A',
  '34B',
  '34C',
  '36B',
  '36C',
  '36D',
  '36DD',
  '38C',
  '38D',
  '38DD',
  '40C',
  '40D',
  '40DD',
  '42C',
  '42D',
  '2T',
  '3T',
  '4T',
  "0", "1",
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  "11",
  '12',
  "13",
  '14',
  '6-9 mo',
  '12 mo',
  '18 mo',
  '0M',
  '3M',
  '6M',
  '9M',
  '12M',
  '18M',
  "A",
  "B",
  "C",
  "D",
  "ONE SIZE",
  "JUNIOR ONE SIZE",
  "34B/S",
  "34C/M",
  "36B/M",
  "4/5",
  "5/6",
  "6/6x",
  "7/8",
  "10/12",

  "14/16", "15", "16",
    "17", "18", "18/20", "19", "20", "21", "22", "22/24", "23", "24",
    "25", "26", "26/28", "27", "28", "29", "30", "30/32",
    "31", "32", "33", "34", "35", "36", "36C/L",
    "36DD/1X", "37", "38", "38C/XL", "38D/1X", "38DD/2X", "39",
    "40", "40D/2X", "40DD/3X", "41", "42", "42D/3X", "43", "44", "45", "46",
    "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57",
    "58", "59", "60"
]

sizes.sort();

const sizesOptions = [];
for (var i=0; i<sizes.length; i++) {
  sizesOptions.push({ key: sizes[i], text: sizes[i], value: sizes[i] })
}

const sizesCheckoutOptions = [];
for (var i=0; i<sizesCheckout.length; i++) {
  sizesCheckoutOptions.push({ key: sizesCheckout[i], text: sizesCheckout[i], value: sizesCheckout[i] })
}

export { bulkStatusOptions, getApiEndpoint, getS3BucketName, getTempApiKey, qcOptions, qcOptionsAdmin, seasonOptions, seasonOptionsAdmin, seasonOptionsDavid, seasonOptionsDavidAdmin, divisionOptions, divisionOptionsAdmin, divisionOptionsFashionNova, brandOptions, brandOptionsAdmin, brandOptionsFashionNova, brandOptionsDavid, brandOptionsDavidAdmin, categoryOptions, categoryOptionsAdmin, categoryOptionsFashionNova, categoryOptionsDdsAshley, colorOptions, colorOptionsAdmin, fabricationOptions, fabricationOptionsAdmin, packsOptions, packsOptionsAdmin, marginsOptionsAdmin, getApplicableSubCategoryOptions, subCategoryOptions,
subCategoryOptionsAdmin, subCategoryOptionsFashionNova, subCategoryOptionsDdsAshley, subCategoryOptionsDavid, babydollSub2_options, babydollSub2_optionsAdmin, braSub2_options, braSub2_optionsAdmin, pantySub2_options, pantySub2_optionsAdmin, brapantysetSub2_options, brapantysetSub2_optionsAdmin, pantSub2_options, pantSub2_optionsAdmin, shortsetSub2_options, shortsetSub2_optionsAdmin, undershirtsSub2_options, undershirtsSub2_optionsAdmin, underwearSub2_options, underwearSub2_optionsAdmin, pjsetSub2_options, pjsetSub2_optionsAdmin, fabricationOptionsDavid, fabricationOptionsDavidAdmin, packsOptionsDavid, packsOptionsDavidAdmin, divisionOptionsDavid, divisionOptionsDavidAdmin, colorOptionsDavid, colorOptionsDavidAdmin, canSeeAccounts, buyerOptions, sizesOptions, salesAdmins, sizesCheckoutOptions, getStripePublicKey }
