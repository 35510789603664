import React from 'react'
import {Message} from 'semantic-ui-react'
import ConfirmExcel from './confirm-excel.js'

const successMessage = (successId, action) => {
  return (
    <div style={{paddingTop: '35px', marginBottom: '-20px'}}>
      <Message>
        Your vendor order has been uploaded successfully.  REF # &nbsp;
        {
          successId.split(',').join(', ')
        }
        {
          action != null
          ?
          <ConfirmExcel successId={successId} action={action}/>
          :
          null
        }
      </Message>
    </div>
  )
}

const splitUpMessage = (splitUpId) => {
  return (
    <div style={{paddingTop: '35px', marginBottom: '-20px'}}>
      <Message>
        Your vendor order was split up into REF # &nbsp;
        {
          splitUpId.split(',').join(', ')
        }
      </Message>
    </div>
  )
}

export {successMessage, splitUpMessage}
